export const limonadyDrinkyList = [
  {
    title: "Limonády vlčie sirupy",
    volume: "Nealko limonády - podľa ponuky 0,04 l",
    price: "3,50 €"
  },
  {
    title: "Gin Tonic",
    volume: "Gin & Vlčí sirup 0,04 l",
    price: "4,50 €"
  },
  {
    title: "Aperol",
    volume: "Aperol & Prosecco 0,04 l",
    price: "4,50 €"
  },
  {
    title: "Paloma",
    volume: "BVD Repák & Grepová sóda 0,04 l",
    price: "4,50 €"
  },
]


export const nealkoList = [
  {
    title: "Kofola Originál",
    volume: "300/500 ml",
    price: "1,40€/1,80€"
  },
  {
    title: "Coca Cola",
    volume: "330 ml",
    price: "2,00€"
  },
  {
    title: "Fanta",
    volume: "330 ml",
    price: "2,00 €"
  },
  {
    title: "Sprite",
    volume: "330 ml",
    price: "2,00 €"
  },
  {
    title: "Cappy džús",
    volume: "podľa ponuky 250 ml",
    price: "2,00 €"
  },
  {
    title: "Fuze Tea",
    volume: "podľa ponuky 250 ml",
    price: "2,00 €"
  },
  {
    title: "Kinley Tonic",
    volume: "podľa ponuky 250 ml",
    price: "2,00 €"
  },
  {
    title: "Natura minerálna voda",
    volume: "300 ml",
    price: "1,40 €"
  },
  {
    title: "Romerquelle",
    volume: "Citrónová tráva 330 ml",
    price: "1,80 €"
  },
  {
    title: "Romerquelle",
    volume: "750 ml",
    price: "3,00 €"
  },
  {
    title: "ISO Fruit",
    volume: "500ml",
    price: "1,80 €"
  }
]

export const paniniList = [
  {
    title: "Šunkové Panini",
    volume: "140 g Maslo, šunka, syr",
    price: "3,80 €"
  },
  {
    title: "Kuracia tortilla",
    volume: "185 g Kuracie prsia, syr",
    price: "3,80 €"
  },
  {
    title: "BBQ tortilla",
    volume: "185 g Trhané bravčové mäso, cheddar, jalapeno",
    price: "3,80 €"
  },

]
export const sishaList = [
  {
    title: "Vodná fajka",
    volume: "Info u obsluhy - Dostupná od 18 rokov - 15 g",
    price: "10,00 €"
  },
]
export const vinkoList = [
  {
    title: "Biele / Červené",
    volume: "1 dcl - podľa ponuky",
    price: "1,60 €"
  },
]


