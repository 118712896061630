
export const pivkoList = [
  {
    title: "Desinka - 10°",
    volume: "300/500 ml",
    price: "1,40 €/1,80 €",
    outlined: true,
    outlinedTop: true,
  },
  {
    title: "Dvanástka - 12°",
    volume: "300/500 ml",
    price: "1,60 €/2,00 €",
    outlined: true,
  },
  {
    title: "Špeciál",
    volume: "podľa ponuky 400 ml",
    price: "2,50 €",
    outlined: true,
  },
  {
    title: "Zlatý Bažant Radler 0 %",
    volume: "300/500 ml",
    price: "1,40 €/1,80 €",
    outlined: true,
    outlinedBottom: true
  },
  {
    title: "Corona",
    volume: "Fľaškové 355 ml",
    price: "2,40 €"
  },
  {
    title: "Desperados",
    volume: "Fľaškové 350 ml",
    price: "2,20 €"
  },
  /* {
    title: "Strongbow",
    volume: "Podľa ponuky 440 ml",
    price: "1,30 €"
  }, */
  {
    title: "Zlatý Bažant nealko",
    volume: "Fľaškové 330/500 ml",
    price: "1,80 €"
  },
  {
    title: "Pilsner Urquell",
    volume: "Podľa ponuky 500 ml",
    price: "2,00 €"
  },
  {
    title: "Heineken",
    volume: "Podľa ponuky 500 ml",
    price: "2,00 €"
  }
]

export const vodkaList = [
  {
    title: "Vodka 40 %",
    volume: "40 ml",
    price: "1,20 €"
  },
  {
    title: "Finlandia 40 %",
    volume: "40 ml",
    price: "1,60 €"
  },
  {
    title: "Absolut 40 %",
    volume: "40 ml",
    price: "1,80 €"
  },
]

export const destilatyList = [
  {
    title: "Slivovica Jelínek 50 %",
    volume: "40 ml",
    price: "2,20 €"
  },
  /* {
    title: "Hruškovica Jelínek 40 %",
    volume: "40 ml",
    price: "2,20 €"
  }, */
  {
    title: "Trnkovica Jelínek 42 %",
    volume: "40 ml",
    price: "2,20 €"
  },
  {
    title: "Repák BVD 50 %",
    volume: "40 ml",
    price: "3,30 €"
  },
  {
    title: "Marhuľovica BVD 45 %",
    volume: "40 ml",
    price: "3,60 €"
  },
]

export const likerList = [
  {
    title: "Fernet Citrus 27 %",
    volume: "40 ml",
    price: "1,60 €"
  },
  {
    title: "Jägermeister 35 %",
    volume: "40 ml",
    price: "2,00 €"
  },
  {
    title: "Tatra Tea Flower 47 %",
    volume: "40 ml",
    price: "1,90 €"
  },
  {
    title: "Tatra Tea Original 52 %",
    volume: "40 ml",
    price: "2,00 €"
  },
  {
    title: "Tatra Tea Forest FR. 62 %",
    volume: "40 ml",
    price: "2,20 €"
  }
]


export const tequilaList = [
  {
    title: "Tequila olmeca silver 35 %",
    volume: "40 ml",
    price: "2,50 €"
  },
]