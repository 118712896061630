
export const borovickaList = [
  {
    title: "Lúčky gin",
    volume: "40 ml",
    outlined: true,
    outlinedTop: true,
    price: "2,50 €"
  },
  {
    title: "Lúčky pink gin",
    volume: "40 ml",
    outlined: true,
    outlinedBottom: true,
    price: "2,70 €"
  },
  {
    title: "Borovička slovenská",
    volume: "40 ml",
    price: "1,20 €"
  },
  {
    title: "Borovička koniferum",
    volume: "40 ml",
    price: "1,60 €"
  },
]





export const rumList = [
  {
    title: "Captain Morgan 35 %",
    volume: "40 ml",
    price: "1,90 €"
  },
  {
    title: "Božkov rum 38 %",
    volume: "40 ml",
    price: "1,90 €"
  },
  {
    title: "Havana Club Añejo rum 37,5 %",
    volume: "40 ml",
    price: "2,50 €"
  },
  {
    title: "Pyrat XO 40 %",
    volume: "40 ml - 15r.",
    price: "4,00 €"
  },
  {
    title: "Diplomatico Reserva 40 %",
    volume: "40 ml - 12r.",
    price: "4,50 €"
  },
  {
    title: "ZACAPA CENTENARIO 40 %",
    volume: "40 ml - 25r.",
    price: "5,80 €"
  },
]


export const whiskeyList = [
  {
    title: "Jameson 40 %",
    volume: "40 ml",
    price: "2,80 €"
  },
  {
    title: "Tullamore Dew 40 %",
    volume: "40 ml",
    price: "2,80 €"
  },
  {
    title: "Jack Daniels 40 %",
    volume: "40 ml",
    price: "3,00 €"
  },
]

export const cognacList = [
  {
    title: "Hennessy V.S. 40 %",
    volume: "40 ml",
    price: "4,40 €"
  }
]
