import {
  GiWaterBottle,
  GiPopcorn,
  GiGlassShot,
  GiPineTree,
  GiPirateCaptain,
  GiBrandyBottle,
  GiLemon,
  GiFlowers
} from "react-icons/gi"
import {
  MdCoffee,
  MdOutlineCoffee,
  MdLiquor
} from "react-icons/md"
import { BiDrink } from "react-icons/bi"
import { IoBeerOutline } from "react-icons/io5"
import { IoIosWine } from "react-icons/io"
import { FaGlassWhiskey } from "react-icons/fa"
import { FaMartiniGlassCitrus } from "react-icons/fa6";
import { CiPizza } from "react-icons/ci";
import { WiSmoke } from "react-icons/wi";
import { TbBaguette } from "react-icons/tb";



import uuid from 'react-uuid';
import { kavickaList, pizzaList, pochutinyList } from "./menu/FrontLeftSide"
import { limonadyDrinkyList, nealkoList, vinkoList, paniniList, sishaList } from "./menu/FrontRightSide"

import { pivkoList, vodkaList, destilatyList, likerList, tequilaList  } from "./menu/BackLeftSide"
import { borovickaList,  rumList, whiskeyList, cognacList } from "./menu/BackRightSide"


const getIcon = (type) => {
  switch (type) {
    case 'NEALKO':
      return <GiWaterBottle size={"35px"}/>
    case 'PANINI':
      return <TbBaguette size={"35px"}/>
    case 'SHISHA':
      return <WiSmoke size={"35px"}/>
    case 'KÁVIČKA':
      return <MdCoffee size={"35px"}/>
    case 'ČAJ A HORÚCE NÁPOJE':
      return <MdOutlineCoffee size={"35px"}/>
    case 'POCHUTINY':
      return <GiPopcorn size={"35px"}/>
    case 'PIVKO':
      return <IoBeerOutline size={"35px"}/>
    case 'VÍNKO':
      return <IoIosWine size={"35px"}/>
    case 'VODKA':
      return <GiGlassShot size={"35px"}/>
    case 'CRAFTOVÉ GINY Z DIELNE BVD':
      return false
    case 'BOROVIČKA':
      return <GiPineTree size={"35px"}/>
    case 'RUM':
      return <GiPirateCaptain size={"35px"}/>
    case 'COGNAC A BRANDY':
      return <GiBrandyBottle size={"35px"}/>
    case 'TEQUILA':
      return <GiLemon size={"35px"}/>
    case 'VOĽBA PRE ŽENY':
      return <GiFlowers size={"35px"}/>
    case 'WHISKEY':
      return <FaGlassWhiskey size={"35px"}/>
    case 'LIKÉR':
      return <MdLiquor size={"35px"}/>
    case 'TATRA TEA':
      return false
    case 'DESTILÁTY A LIEHOVINY':
      return <BiDrink size={"35px"}/>
    case 'LIMONÁDY & DRINKY':
      return <FaMartiniGlassCitrus size={"40px"}/>
    case 'PIZZA':
      return <CiPizza size={"40px"}/>

    default:
      break;
  }
}


export const MenuSectionFrontLeft = [
  {
    icon: getIcon("KÁVIČKA"),
    title: "KÁVA & ČAJ",
    outlined: false,
    list: kavickaList
  },
  {
    icon: getIcon("PIZZA"),
    title: "PIZZA",
    outlined: false,
    list: pizzaList
  },
  {
    icon: getIcon("POCHUTINY"),
    title: "POCHUTINY",
    outlined: false,
    list: pochutinyList
  },
  {
    icon: getIcon("VÍNKO"),
    title: "VÍNO",
    outlined: false,
    list: vinkoList
  },
  /* {
    icon: getIcon("SHISHA"),
    title: "SHISHA",
    outlined: false,
    list: sishaList
  }, */
].map(element => { return {
  ...element,
  list: element.list.map(item => {
      return {
        ...item,
        uuid: uuid(),
        location: "frontLeft"
      }
    })
} })

export const MenuSectionFrontRight = [
  {
    icon: getIcon("LIMONÁDY & DRINKY"),
    title: "LIMONÁDY & DRINKY",
    outlined: false,
    list: limonadyDrinkyList
  },
  {
    icon: getIcon("NEALKO"),
    title: "NEALKO NÁPOJE",
    outlined: false,
    list: nealkoList
  },
  {
    icon: getIcon("PANINI"),
    title: "PANINI & TORTILLA",
    outlined: true,
    list: paniniList
  },/*
  {
    icon: getIcon("VÍNKO"),
    title: "VÍNKO",
    outlined: false,
    list: vinkoList
  } */
].map(element => { return {
  ...element,
  list: element.list.map(item => {
      return {
        ...item,
        uuid: uuid(),
        location: "frontRight"
      }
    })
} })


export const MenuSectionBackLeft = [

  {
    icon: getIcon("PIVKO"),
    title: "PIVKO",
    outlined: false,
    list: pivkoList
  },
  {
    icon: getIcon("VODKA"),
    title: "VODKA",
    outlined: false,
    list: vodkaList
  },
  {
    icon: getIcon("DESTILÁTY A LIEHOVINY"),
    title: "DESTILÁTY",
    outlined: false,
    list: destilatyList
  },
  {
    icon: getIcon("COGNAC A BRANDY"),
    title: "COGNAC",
    outlined: false,
    list: cognacList
  },
  {
    icon: getIcon("TEQUILA"),
    title: "Tequilla",
    outlined: false,
    list: tequilaList
  },
  /*
  {
    icon: getIcon("CRAFTOVÉ GINY Z DIELNE BVD"),
    title: "CRAFTOVÉ GINY Z DIELNE BVD",
    outlined: true,
    list: bvdList
  }, */

].map(element => { return {
  ...element,
  list: element.list.map(item => {
      return {
        ...item,
        uuid: uuid(),
        location: "backLeft"
      }
    })
} })


export const MenuSectionBackRight = [
  {
    icon: getIcon("COGNAC A BRANDY"),
    title: "GIN & BOROVIČKA",
    outlined: false,
    list: borovickaList
  },
  {
    icon: getIcon("RUM"),
    title: "RUM",
    outlined: false,
    list: rumList
  },
  {
    icon: getIcon("WHISKEY"),
    title: "WHISKEY",
    outlined: false,
    list: whiskeyList
  },
  /* {
    icon: getIcon("VOĽBA PRE ŽENY"),
    title: "VOĽBA PRE ŽENY",
    outlined: false,
    list: prezenyList
  }, */
  {
    icon: getIcon("LIKÉR"),
    title: "LIKÉR",
    outlined: false,
    list: likerList
  },
  /* {
    icon: getIcon("TATRA TEA"),
    title: "TATRA TEA",
    outlined: true,
    list: tatrateaList
  }, */
].map(element => { return {
  ...element,
  list: element.list.map(item => {
      return {
        ...item,
        uuid: uuid(),
        location: "backRight"
      }
    })
} })


